import { TextareaAutosize } from "@mui/material";

function TextareaAutosizeComponent(props: TextAreaParamsField) {
    return (
        <TextareaAutosize
            value={props.value}
            placeholder={props.placeholder}
            minRows={8}
            style={{
                color: props.color ? props.color : "#001e50",
                fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                fontSize: props.fontSize ? props.fontSize : "16px",
                fontWeight: props.fontWeight ? props.fontWeight : "normal",
                fontStretch: props.fontStretch ? props.fontStretch : "normal",
                fontStyle: props.fontStyle ? props.fontStyle : "normal",
                lineHeight: props.lineHeight ? props.lineHeight : "1.5",
                letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
                width: "99.8%",
                margin: "normal"
            }}
            readOnly={props.readOnly ? true : false}
            onChange={props.onChangeArea} 
            // onResize={undefined} 
            // onResizeCapture={undefined} 
                   />
    );
}

export default TextareaAutosizeComponent;